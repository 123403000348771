import { logError } from '../../../../lib/utils/logger'

export default (() => {
  class ChannelDescController {
    constructor({ selector }) {
      this.elem = document.querySelector(selector)
      if (!this.elem) return

      if (this.elem.classList.contains('ncidForward')) {
        this.initNCIDForwarder(selector)
      }
    }

    initNCIDForwarder(selector) {
      const { ncidForwarder } = window.NEXUtils || {}
      if (!ncidForwarder) {
        logError('ChannelDesc', 'NCID Forwarder not found')
        return
      }

      ncidForwarder.addTrackedLinksSelector(`${selector} a`)
    }
  }

  return ChannelDescController
})()
